import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import { setDocumentTitle } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { loadAnalysisDataStart } from '../../../store/analysisSlice';
import { filter, tidy } from '@tidyjs/tidy';
import AnalysisQuestion from '../../../components/AnalysisQuestion';

import TourGuide from '../../../components/TourGuide';

export default function DefaultAnalysisPage() {
	const dispatch = useDispatch();

	const { setCurrentPageTab } = useOutletContext();
	const { nanoid } = useParams();

	useEffect(() => {
		setCurrentPageTab('questions');
	}, [setCurrentPageTab]);

	const { t } = useTranslation();

	const projectAnalysis = useSelector(state => state.analysis.projects[nanoid]);

	const rawData = projectAnalysis ? projectAnalysis.rawData : [];
	const filteredData = projectAnalysis ? projectAnalysis.filteredData : [];

	const translations = projectAnalysis?.translations;
	const displayLanguage = projectAnalysis?.translations.displayLanguage;

	const questionList = useSelector(
		state => state.projectEditor.questionnaire.questionList,
	);

	useEffect(() => {
		setDocumentTitle(t('pageTitles.projectEditor.analysis'));
	}, [t]);

	useEffect(() => {
		dispatch(
			loadAnalysisDataStart({
				projectNanoId: nanoid,
			}),
		);
	}, [dispatch, nanoid]);

	const steps = [
		{
			target: 'body',
			placement: 'center',
			title: t('tour.content.analysis.reporting.title'),
			content: t('tour.content.analysis.reporting.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-progress',
			placement: 'auto',
			title: t('tour.content.analysis.responseProgress.title'),
			content: t('tour.content.analysis.responseProgress.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-export',
			placement: 'auto',
			title: t('tour.content.analysis.export.title'),
			content: t('tour.content.analysis.export.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-question',
			placement: 'auto',
			title: t('tour.content.analysis.questions.title'),
			content: t('tour.content.analysis.questions.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-filter',
			placement: 'auto',
			title: t('tour.content.analysis.filters.title'),
			content: t('tour.content.analysis.filters.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-compare',
			placement: 'auto',
			title: t('tour.content.analysis.compare.title'),
			content: t('tour.content.analysis.compare.content'),
			disableBeacon: true,
		},
	];

	const filteredResponsesPerQuestion = {};
	const rawResponsesPerQuestion = {};
	questionList.forEach(question => {
		filteredResponsesPerQuestion[question.nanoid] = tidy(
			filteredData,
			filter(d => {
				return d[question.nanoid] !== undefined;
			}),
		);
		rawResponsesPerQuestion[question.nanoid] = tidy(
			rawData,
			filter(d => {
				return d[question.nanoid] !== undefined;
			}),
		);
	});

	if (!projectAnalysis) {
		return <></>;
	}

	return (
		<>
			<div className="mx-auto pt-4 max-w-4xl flex flex-col gap-4">
				{questionList.map((question, index) => (
					<AnalysisQuestion
						key={question.nanoid}
						translations={translations}
						language={displayLanguage}
						filteredData={filteredResponsesPerQuestion[question.nanoid]}
						index={index + 1}
						question={question}
						responseCount={rawResponsesPerQuestion[question.nanoid].length}
						filteredResponseCount={
							filteredResponsesPerQuestion[question.nanoid].length
						}
					/>
				))}
			</div>
			<div className="mx-auto pt-4 max-w-4xl mb-40 text-xs text-gray-400 px-4 sm:px-0">
				{t('analysis.disclaimer')}
			</div>

			<TourGuide tourName={'analysisPage'} steps={steps} />
		</>
	);
}
