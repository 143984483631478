import ReactPlayer from 'react-player';
import React, { useEffect, useState } from 'react';
import FileUploadArea from './FileUploadArea';
import MultipleSelectionQuestion from './MultipleSelectionQuestion';
import SingleSelectionQuestion from './SingleSelectionQuestion';
import TextQuestion from './TextQuestion';
import YesNoQuestion from './YesNoQuestion';
import NumberSelectionQuestion from './NumberSelectionQuestion';
import RankingQuestion from './RankingQuestion';
import OpinionScaleQuestion from './OpinionScaleQuestion';
import RatingQuestion from './RatingQuestion';
import MatrixQuestion from './MatrixQuestion';
import logicOperator from './utils/logicOperator';
import { cloneDeep, map, shuffle } from 'lodash';
import Spinner from '../Spinner';
import Alert from '../Alert';
import { Trans } from 'react-i18next';
import QuestionHints from './QuestionHints';
import { displayMentionIfExists } from './utils/utils';
import { getQuestionTitle } from '../../utils';

export default function Questionnaire({
	questionnaireData,
	onSubmitQuestionnaire,
	showPreviousButton = false,
	activeQuestionNanoId = null,
	currentMode = 'loading',
}) {
	const [questionList, setQuestionList] = useState([]);
	const [answers, setAnswers] = useState({}); // {questionNanoId: answerValue}
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
	const [currentQuestion, setCurrentQuestion] = useState(null);
	const [doEndQuestionnaire, setDoEndQuestionnaire] = useState(false);
	const [questionHistory, setQuestionHistory] = useState([]);
	const [questionNanoIds, setQuestionNanoIds] = useState({});

	const goToQuestionByIndex = (index, question, previous = false) => {
		if (!previous) {
			const _questionHistory = cloneDeep(questionHistory);
			_questionHistory.push(currentQuestionIndex);
			setQuestionHistory(_questionHistory);
		}

		setCurrentQuestionIndex(index);
		question
			? setCurrentQuestion(question)
			: setCurrentQuestion(questionList[index]);
	};

	const goToQuestion = targetQuestionNanoId => {
		const foundIndex = questionList.findIndex(_question => {
			return _question.nanoid === targetQuestionNanoId;
		});
		goToQuestionByIndex(foundIndex);
	};

	useEffect(() => {
		let finalQuestionList = [];

		let screeningQuestionList = [];
		let generalQuestionList = [];
		questionnaireData.questions.map(question => {
			question.is_screening
				? screeningQuestionList.push(question)
				: generalQuestionList.push(question);
		});

		if (questionnaireData.is_randomized) {
			generalQuestionList = shuffle(generalQuestionList);
		}

		/* *************************** */
		if (
			generalQuestionList[0].nanoid === 'HcBYqb4evh1pBJy-' ||
			generalQuestionList[0].nanoid === 'NLGI8Zuzjjkpf5Wq'
		) {
			const nanoids = generalQuestionList.reduce((acc, curr, index) => {
				acc[index + 1] = curr.nanoid;
				return acc;
			}, {});

			setQuestionNanoIds(nanoids);

			const matrixQ = generalQuestionList[7];
			const eighthQuestion = { ...matrixQ, rows: shuffle(matrixQ.rows) };
			generalQuestionList.splice(7, 1, eighthQuestion);
		}

		/* ************************** */

		finalQuestionList = screeningQuestionList.concat(generalQuestionList);

		setQuestionList(finalQuestionList);

		let questionIndexToSet = 0; //Start from the first question if activeQuestionNanoId is not set

		if (activeQuestionNanoId) {
			const foundIndex = finalQuestionList.findIndex(_question => {
				return _question.nanoid === activeQuestionNanoId;
			});

			if (foundIndex > -1) {
				const screeningListLength = screeningQuestionList.length;

				if (screeningListLength > 0 && foundIndex === screeningListLength) {
					questionIndexToSet = 0;
				} else {
					questionIndexToSet = foundIndex;
				}
			}
		}

		setCurrentQuestion(finalQuestionList[questionIndexToSet]);
		setCurrentQuestionIndex(questionIndexToSet);
	}, [questionnaireData, activeQuestionNanoId]);

	const goToNextQuestion = answer => {
		//**for project  */

		const upcomingQuestion = questionList[currentQuestionIndex + 1] || null;
		if (questionList[1].nanoid === questionNanoIds['1'] && upcomingQuestion) {
			const secondQ = questionList.find(q => q.nanoid === questionNanoIds['2']);

			let question = upcomingQuestion;

			if (upcomingQuestion.nanoid === questionNanoIds['3']) {
				const answerTitles = secondQ.choices
					.filter(choice => answer.includes(choice.nanoid))
					.map(el => el.title);

				const newChoices = upcomingQuestion.choices.filter(choice =>
					answerTitles.includes(choice.title),
				);

				question = { ...upcomingQuestion, choices: newChoices };

				goToQuestionByIndex(currentQuestionIndex + 1, question);
			} else {
				if (currentQuestionIndex === questionList.length - 1) {
					setDoEndQuestionnaire(true);
				} else {
					goToQuestionByIndex(currentQuestionIndex + 1);
				}
			}
			/*for project */
		} else {
			if (currentQuestionIndex === questionList.length - 1) {
				setDoEndQuestionnaire(true);
			} else {
				goToQuestionByIndex(currentQuestionIndex + 1);
			}
		}
	};

	const goToPreviousQuestion = () => {
		if (questionHistory.length === 0) {
			return;
		}

		const _questionHistory = cloneDeep(questionHistory);
		const previousQuestionIndex = _questionHistory.pop();
		setQuestionHistory(_questionHistory);

		goToQuestionByIndex(previousQuestionIndex, undefined, true);
	};

	useEffect(() => {
		if (doEndQuestionnaire) {
			const formattedAnswers = map(answers, (answer, question) => {
				return {
					question: question,
					response: answer,
				};
			});

			onSubmitQuestionnaire(formattedAnswers);
			setDoEndQuestionnaire(false);
		}
	}, [doEndQuestionnaire, onSubmitQuestionnaire, answers]);

	const handleQuestionSubmit = ({
		question,
		answer,
		otherAnswerValue = null,
	}) => {
		/*
		 * Store question answers in state
		 * Route to next question or complete
		 */

		const newAnswers = {
			...answers,
			[question.nanoid]: answer,
		};

		if (otherAnswerValue) {
			newAnswers[otherAnswerValue.question.nanoid] = otherAnswerValue.answer;
		}

		setAnswers(newAnswers);

		if (question.nanoid === questionNanoIds['4']) {
			let newQuestionList = [...questionList];
			const fourthQuestion = questionList.find(
				q => q.nanoid === questionNanoIds['4'],
			);
			const answerTitles = fourthQuestion.choices
				.filter(choice => answer.includes(choice.nanoid))
				.map(el => el.title);

			if (!answerTitles.includes('Can Bonomo')) {
				newQuestionList = newQuestionList.filter(
					q => q.nanoid !== questionNanoIds['12'],
				);
			}
			if (!answerTitles.includes('Murda')) {
				newQuestionList = newQuestionList.filter(
					q => q.nanoid !== questionNanoIds['13'],
				);
			}
			if (!answerTitles.includes('Mahmut Orhan')) {
				newQuestionList = newQuestionList.filter(
					q => q.nanoid !== questionNanoIds['14'],
				);
			}

			setQuestionList(newQuestionList);
		}

		if (question.is_screening) {
			let continueQuestionnaire = false;
			if (question.question_type === 'multiple_selection') {
				if (question.nanoid === 'LO4zO-3yqfincL4H') {
					const selectedAnswers = answer.filter(
						answer =>
							question.choices.find(choice => choice.nanoid === answer)
								.is_qualified,
					);
					continueQuestionnaire = selectedAnswers.length >= 2;
				} else {
					continueQuestionnaire = answer.some(
						answr =>
							question.choices.find(choice => choice.nanoid === answr)
								.is_qualified,
					);
				}
			} else {
				continueQuestionnaire = question.choices.find(
					choice => choice.nanoid === answer,
				).is_qualified;
			}

			if (continueQuestionnaire) {
				goToNextQuestion(answer);
			} else {
				setDoEndQuestionnaire(true);
			}

			return;
		}

		if (question.logic.rules.length > 0) {
			const logicResult = logicOperator(question, questionList, newAnswers);

			switch (logicResult.action) {
				case 'go_to_next_question':
					goToNextQuestion();
					break;
				case 'go_to_question':
					goToQuestion(logicResult.question);
					break;
				case 'end_survey':
					setDoEndQuestionnaire(true);
					break;
				default:
					break;
			}
		} else {
			goToNextQuestion(answer);
		}
	};

	const lastQuestion = currentQuestionIndex === questionList.length - 1;
	const isPreviousDisabled = questionHistory.length == 0;

	const renderPreview = () => {
		if (!currentQuestion) {
			return <></>;
		}

		switch (currentQuestion.question_type) {
			case 'single_selection':
				return (
					<SingleSelectionQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						showPreviousButton={showPreviousButton}
						handlePrevious={goToPreviousQuestion}
						isPreviousDisabled={isPreviousDisabled}
						handleQuestionSubmit={handleQuestionSubmit}
						answer={null}
						questionList={questionList}
						lastQuestion={lastQuestion}
					/>
				);
			case 'multiple_selection':
				return (
					<MultipleSelectionQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						showPreviousButton={showPreviousButton}
						handlePrevious={goToPreviousQuestion}
						isPreviousDisabled={isPreviousDisabled}
						handleQuestionSubmit={handleQuestionSubmit}
						answer={null}
						questionList={questionList}
						canReorder={false}
						lastQuestion={lastQuestion}
					/>
				);
			case 'yes_no':
				return (
					<YesNoQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						showPreviousButton={showPreviousButton}
						handlePrevious={goToPreviousQuestion}
						isPreviousDisabled={isPreviousDisabled}
						handleQuestionSubmit={handleQuestionSubmit}
						answer={null}
						questionList={questionList}
						lastQuestion={lastQuestion}
					/>
				);
			case 'text':
				return (
					<TextQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						showPreviousButton={showPreviousButton}
						handlePrevious={goToPreviousQuestion}
						isPreviousDisabled={isPreviousDisabled}
						handleQuestionSubmit={handleQuestionSubmit}
						answer={null}
						questionList={questionList}
						isEditMode={false}
						lastQuestion={lastQuestion}
					/>
				);
			case 'description':
				return (
					<TextQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						showPreviousButton={showPreviousButton}
						handlePrevious={goToPreviousQuestion}
						isPreviousDisabled={isPreviousDisabled}
						handleQuestionSubmit={handleQuestionSubmit}
						lastQuestion={lastQuestion}
					/>
				);
			case 'number':
				return (
					<NumberSelectionQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						showPreviousButton={showPreviousButton}
						handlePrevious={goToPreviousQuestion}
						isPreviousDisabled={isPreviousDisabled}
						handleQuestionSubmit={handleQuestionSubmit}
						answer={null}
						questionList={questionList}
						isEditMode={false}
						lastQuestion={lastQuestion}
					/>
				);
			case 'rating':
				return (
					<RatingQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						showPreviousButton={showPreviousButton}
						handlePrevious={goToPreviousQuestion}
						isPreviousDisabled={isPreviousDisabled}
						handleQuestionSubmit={handleQuestionSubmit}
						answer={null}
						questionList={questionList}
						isEditMode={false}
						lastQuestion={lastQuestion}
					/>
				);
			case 'opinion_scale':
				return (
					<OpinionScaleQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						showPreviousButton={showPreviousButton}
						handlePrevious={goToPreviousQuestion}
						isPreviousDisabled={isPreviousDisabled}
						handleQuestionSubmit={handleQuestionSubmit}
						lastQuestion={lastQuestion}
					/>
				);
			case 'nps':
				return (
					<OpinionScaleQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						showPreviousButton={showPreviousButton}
						handlePrevious={goToPreviousQuestion}
						isPreviousDisabled={isPreviousDisabled}
						handleQuestionSubmit={handleQuestionSubmit}
						lastQuestion={lastQuestion}
					/>
				);
			case 'file_upload':
				return (
					<FileUploadArea
						key={currentQuestion.nanoid}
						question={currentQuestion}
						answer={null}
						isEditMode={false}
						showPreviousButton={showPreviousButton}
						handlePrevious={goToPreviousQuestion}
						isPreviousDisabled={isPreviousDisabled}
						handleQuestionSubmit={handleQuestionSubmit}
						lastQuestion={lastQuestion}
					/>
				);
			case 'ranking':
				return (
					<RankingQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						showPreviousButton={showPreviousButton}
						handlePrevious={goToPreviousQuestion}
						isPreviousDisabled={isPreviousDisabled}
						handleQuestionSubmit={handleQuestionSubmit}
						answer={null}
						questionList={questionList}
						isEditMode={false}
						lastQuestion={lastQuestion}
					/>
				);
			case 'matrix':
				return (
					<MatrixQuestion
						key={currentQuestion.nanoid}
						question={currentQuestion}
						showPreviousButton={showPreviousButton}
						isPreviousDisabled={isPreviousDisabled}
						handlePrevious={goToPreviousQuestion}
						handleQuestionSubmit={handleQuestionSubmit}
						questionList={questionList}
						lastQuestion={lastQuestion}
					/>
				);
			default:
				return <></>;
		}
	};

	if (currentMode === 'loading') {
		return <Spinner className="mx-auto w-8 h-8 text-mint-600" />;
	}

	return (
		<>
			<div className="w-full bg-white shadow border border-gray-200 sm:rounded-md">
				<div className="">
					<div className="flex flex-row gap-3 items-center px-6 py-4">
						<div className="flex-shrink-0 rounded border bg-mint-100 border-mint-700 text-mint-700 h-12 w-12 text-center text-xl font-bold grid place-content-center ">
							<div>{currentQuestionIndex + 1}</div>
						</div>
						<div className="flex flex-col flex-wrap sm:flex-nowrap">
							<h3 className="text-lg font-medium leading-6 text-gray-800">
								{currentQuestion &&
									Object.keys(answers).length > 0 &&
									displayMentionIfExists(
										currentQuestion.title,
										answers,
										questionList,
									)}
								{currentQuestion &&
									Object.keys(answers).length === 0 &&
									getQuestionTitle(currentQuestion.title, questionList)}
							</h3>
							{currentQuestion && currentQuestion.description && (
								<p className="mt-1 text-xs text-gray-500">
									{currentQuestion.description}
								</p>
							)}
						</div>
					</div>
				</div>
				<div className="flex flex-col overflow-y-auto">
					<div>
						<div className="inline-block min-w-full">
							<div className="border-t border-gray-200">
								<div>
									{currentMode === 'error' && (
										<Alert type={'error'}>
											<Trans>
												An unexpected error occurred. Your response could not be
												saved. Please try again in a few minutes.
											</Trans>
										</Alert>
									)}
									{currentMode === 'thank_you' && (
										<Alert type={'success'}>
											<Trans>Thank you! Your response has been saved.</Trans>
										</Alert>
									)}

									{currentMode === 'question' && currentQuestion && (
										<div className="px-6 py-4">
											<div className="flex flex-col gap-4">
												{currentQuestion.media &&
													currentQuestion.media.media_type === 'image' && (
														<img
															className="rounded"
															src={currentQuestion.media.url}
														/>
													)}
												{currentQuestion.media &&
													currentQuestion.media.media_type === 'video' && (
														<ReactPlayer
															controls={true}
															url={currentQuestion.media.stream_url}
															width={'100%'}
															height={'100%'}
														/>
													)}
												<QuestionHints question={currentQuestion} />
												{renderPreview()}
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
