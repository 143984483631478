import { ResponsiveBar } from '@nivo/bar';
import { useState, useEffect } from 'react';

const calculateSpaceForLegend = comparisonItemCount => {
	return 80 + comparisonItemCount * 20;
};

export default function BarChart({
	type = 'percentage',
	minValue = 0,
	maxValue = 0,
	tickValues = 0,
	layout = 'horizontal',
	groupMode = 'stacked',
	legend,
	forceShowLegend = false,
	keys,
	data,
	legendLabels,
	isCompareMode = false,
}) {
	const legends =
		isCompareMode || forceShowLegend
			? [
					{
						dataFrom: 'keys',
						anchor: 'bottom-left',
						direction: 'column',
						justify: false,
						translateX: 0,
						translateY: calculateSpaceForLegend(keys.length),
						itemsSpacing: 2,
						itemWidth: 220,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 20,
						effects: [
							{
								on: 'hover',
								style: {
									itemOpacity: 1,
								},
							},
						],
					},
			  ]
			: [];

	const bottomMargin =
		isCompareMode || forceShowLegend
			? calculateSpaceForLegend(keys.length)
			: 50;

	let valueScale = '>-1.2%';
	if (type === 'count') {
		valueScale = null;
	}
	if (type === 'absolute') {
		valueScale = '>-.2';
	}

	const axisFormat =
		type === 'percentage'
			? value => {
					return `${(value * 100).toFixed(0)}%`;
			  }
			: value => {
					return `${value}`;
			  };
	const axisMinValue = type === 'percentage' ? 0 : minValue;
	const axisMaxValue = type === 'percentage' ? 1 : maxValue;
	const axisTickValues = () => {
		switch (type) {
			case 'percentage':
				return 10;
			case 'count':
				return maxValue < 10 ? maxValue : 10;
			default:
				return tickValues;
		}
	};

	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		if (window.innerWidth < 500) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	}, []);

	let longestCountryLength = 0;

	data.forEach(item => {
		if (longestCountryLength < item.country.length) {
			longestCountryLength = item.country.length;
		}
	});

	function getCountryNameFromId(nanoid) {
		const item = data.find(d => d.countryId && d.countryId === nanoid);
		return item ? item.country : nanoid;
	}

	return (
		<ResponsiveBar
			data={data}
			keys={keys}
			indexBy={item => {
				return item.countryId ? item.countryId : item.country;
			}}
			groupMode={groupMode}
			margin={{
				top: layout === 'vertical' ? 5 : 0,
				right: 15,
				bottom: bottomMargin,
				left:
					(longestCountryLength > 80 && 80 * 5.5) ||
					longestCountryLength * 6 + 10,
			}}
			padding={0.3}
			layout={layout}
			valueScale={{ type: 'linear' }}
			valueFormat={valueScale}
			minValue={axisMinValue}
			maxValue={axisMaxValue}
			indexScale={{ type: 'band', round: true }}
			colors={['#4f46e5', '#0d9488', '#e11d48']}
			borderColor={{
				from: 'color',
				modifiers: [['darker', 1.6]],
			}}
			enableGridX={true}
			axisLeft={{
				format: v => {
					const title = getCountryNameFromId(v);
					return title.length > 80 ? (
						<tspan>
							{title.substring(0, 79) + '...'}
							<title>{title}</title>
						</tspan>
					) : (
						title
					);
				},
			}}
			enableGridY={false}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				tickValues: axisTickValues(),
				legend: legend,
				legendPosition: 'middle',
				legendOffset: 40,
				format: axisFormat,
			}}
			labelSkipWidth={1}
			labelSkipHeight={12}
			labelTextColor={{
				from: 'color',
				modifiers: [['brighter', 3]],
			}}
			legendLabel={e => {
				return legendLabels[e.id] ? legendLabels[e.id] : e.id;
			}}
			legends={legends}
			role="application"
			{...(isMobile && { width: 900 })} // if mobile screen add width if it is not, do not add that line
		/>
	);
}
